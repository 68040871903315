<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="10"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title="HAVE QUESTIONS? LET US KNOW!"
            />

            <base-body space="0">
              <div class="white--text">
                Just fill out our contact us form and we will get back with you as soon as possible.



              </div>
							<div class="white--text">
								If you need faster attention, please give us a call right away.
							</div>
							<p class="yellow--text">Phone: 855-699-3282</p>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              href="mailto:support@datacombusinessystems.com"
              large
              outlined
              target="_blank"
            >
              Contact Us
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionContactUs',
  }
</script>
